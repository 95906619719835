import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import { closeNavigation, toggleNavigation } from 'state/actions/navigation';
import { nav_routes } from 'helpers/routes';

import Nav from 'e84-website-styles/src/components/Nav';
import NavToggle from 'e84-website-styles/src/components/NavToggle';

import labslogo from 'assets/images/labs/LabsLogo.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faBars from '@fortawesome/fontawesome-free-solid/faBars';

const Header = ({isOpen, closeNav, toggleNav}) => {
  return (
    <header className="site-header">
      <div className="container">

        <Link to="/" className="site-header__title">
          <img src={labslogo} alt="E84 Logo"/>
        </Link>

        <div className="site-header__right">

          <NavToggle isOpen={isOpen} onClick={toggleNav}>
            <FontAwesomeIcon icon={faBars} />
          </NavToggle>

          <Nav isOpen={isOpen} closeNav={closeNav} toggleNav={toggleNav} showPanelLogo={false} routes={nav_routes} />

        </div>

      </div>
    </header>
  );
};

Header.propTypes = {
  isOpen: PropTypes.bool,
  closeNav: PropTypes.func,
  toggleNav: PropTypes.func,
};

const mapStateToProps = ({ navigation }) => ({
  isOpen: navigation
});

const mapDispatchToProps = dispatch => ({
  closeNav: () => dispatch(closeNavigation()),
  toggleNav: () => dispatch(toggleNavigation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);