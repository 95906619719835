const nav_routes = [
  {
    id: 'about',
    to: 'https://www.element84.com/about-us/',
    label: 'About',
  },
  {
    id: 'work',
    to: 'https://www.element84.com/work/',
    label: 'Work',
  },
  {
    id: 'careers',
    to: 'https://www.element84.com/careers/',
    label: 'Careers',
  },
  {
    id: 'contact',
    to: 'https://www.element84.com/contact/',
    label: 'Contact',
  },
  {
    id: 'blog',
    to: 'https://www.element84.com/blog/',
    label: 'Blog',
  },
  {
    id: 'labs',
    to: 'https://labs.element84.com/',
    label: 'Labs',
    divided: true,
  },
];

module.exports.nav_routes = nav_routes;
